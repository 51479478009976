import React from 'react';

import { 
  Wrapper,
  SmartLogo,
  FooterMenu,
  Copyright
} from './styles';

const Footer = () => {
  return (
    <Wrapper>
      <SmartLogo 
        alt="Ícone da SmartEnvios acima do menu do rodapé."
      />

      <FooterMenu>
        <Copyright>SmartEnvios - Todos os Direitos Reservados</Copyright>
      </FooterMenu>

    </Wrapper>
  );
}

export default Footer;