import React, { useState } from 'react';

import Smart from '../../assets/images/smart-logo.svg';
import OtherSmart from '../../assets/images/smartIcon.svg';

import {
  Wrapper,
  HeaderContent,
  HeaderMenu,
  MobileMenu,
  MenuWrapper,
  Ul,
  ListMenu,
} from './styles';

const Header = () => {
  const [open, setOpen] = useState(true);

  return (
    <>
      <Wrapper id="wrapper">

        <HeaderContent id="topo">
          <Smart
            id="smart-logo"
            alt="Icone da SmartEnvios ao lado esquerdo do menu de opções"
          />

          <OtherSmart
            id="other-smart"
            alt="Icone da SmartEnvios sem texto ao lado esquerdo do texto Base de conhecimento"
          />

          <div />

          <h2>Base de Conhecimento</h2>
        </HeaderContent>

        <HeaderContent>
          <HeaderMenu cover bg="#fff" to="/">Home</HeaderMenu>
          <HeaderMenu cover bg="#fff" to="/transportadoras/">Transportadoras</HeaderMenu>
          <HeaderMenu cover bg="#fff" to="/todas-categorias/">Categorias</HeaderMenu>
        </HeaderContent>

        <MobileMenu open={open} onClick={() => setOpen(!open)}>
          <div />
          <div />
          <div />
        </MobileMenu>

      </Wrapper>

      <MenuWrapper open={open}>
        <Ul>
          <ListMenu cover bg="#fff" to="/">Home</ListMenu>
          <ListMenu cover bg="#fff" to="/transportadoras">Transportadoras</ListMenu>
          <ListMenu cover bg="#fff" to="/todas-categorias">Categorias</ListMenu>
        </Ul>
      </MenuWrapper>
    </>
  );
};

export default Header;