import styled from 'styled-components';

export const SearchWrapper = styled.section`
  position: relative;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
  
  #search-icon {
    width: 25px;
    height: 25px;
    left: 10px;
    top: 23px;
    position: absolute;
    fill: #2A5072;

    @media (max-width: 531px) {
      width: 20px;
      height: 20px;
      top: 16px;
    }
  }
  
  .ais-InstantSearch__root {
    display: flex;
    flex-direction: column;
    height: auto;
    width: auto;
  }

  .ais-Hits {
    position: absolute;
    background: #Fff;
    z-index: 10000000;
    list-style: none;
  }

  .ais-SearchBox {
  }

  .ais-SearchBox-input {
    height: 70px;
    width: 77vw;
    text-align: left;
    padding-left: 48px;
    padding-right: 12px;
    font-size: 14px;
    color: #044D75;
    border-radius: 4px 4px 0px 0px;

    @media (min-width: 3000px) {
      width: 40vw;
    }

    @media (min-width: 1920px) {
      width: 20vw;
    }

    @media (min-width: 1400px) {
      width: 40vw;
    }

    @media (max-width: 531px) {
      font-size: 14px;
      height: 50px;
      width: 90vw;
      padding-left: 45px;
    }

    ::-webkit-input-placeholder { 
      color:#044D75;
      text-align: left;

      @media (max-width: 531px) {
        font-size: 14px;
      }
    }

    ::-moz-placeholder { /* Mozilla Firefox 19+ */
      color:#044D75;
      font-size: 20px;
      text-align: left;

      @media (max-width: 531px) {
        font-size: 14px;
      }
    }

    &:focus {
      outline: none;
    }
  }

  .ais-SearchBox-submit,
  .ais-SearchBox-reset {
    display: none;
  }
`;