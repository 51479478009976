import React, { useState, useRef, useEffect } from 'react';

import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits, Pagination } from 'react-instantsearch-dom';

import SearchIcon from '../../assets/images/search.svg';

import Hit from '../SearchHit';
import HitNull from './HitNull';

import { SearchWrapper } from './styles';

const algolia = {
  appID: process.env.GATSBY_ALGOLIA_APP_ID,
  searchOnlyApiKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
  indexName: process.env.GATSBY_ALGOLIA_INDEX_NAME,
};

const searchClient = algoliasearch(
  algolia.appID,
  algolia.searchOnlyApiKey,
);

const Search = () => {
  const { ref, isComponentVisible } = useComponentVisible(true);
  const [input, setInput] = useState('');

  function useComponentVisible(initialIsVisible) {
    const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
    const ref = useRef(false);

    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsComponentVisible(false);
      } else {
        setIsComponentVisible(true);
      };
    };

    useEffect(() => {
      document.addEventListener('click', handleClickOutside, true);

      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    });

    return { ref, isComponentVisible, setIsComponentVisible };
  };

  return (
    <SearchWrapper
      isComponentVisible={isComponentVisible}
      input={input}
      ref={ref} alt="Campo de pesquisa da base de conhecimentos"
    >
      <InstantSearch
        searchClient={searchClient}
        indexName={algolia.indexName}
      >
        <SearchIcon
          id="search-icon"
          alt="Ícone de pesquisa ao lado esquerdo do texto de busca"
        />
        <SearchBox
          onChange={e => setInput(e.target.value)}
          translations={{ placeholder: 'O que você está procurando?' }}
        />
        {isComponentVisible &&
          <Hits hitComponent={input !== '' ? Hit : HitNull} />
        }
      </InstantSearch>
    </SearchWrapper>
  );
}

export default Search;