import styled from 'styled-components';

export const Wrapper = styled.section`
  #top-arrow {
    height: 0px;
    width: 0px;
    position: fixed;
    fill: #044D75;
    bottom: 60px;
    left: 20px;
    z-index:100;
    transition: fill;
    cursor: pointer;

    @media(max-width: 767px) {
      height: 50px;
      width: 50px;
    }

    @media (max-width: 411px) {
      height: 30px;
      width: 30px;
    }

    &:hover {
      fill: #489be8;
    }
  }
`;