import styled from 'styled-components';

export const Wrapper = styled.section`
  background: #DCEDF9;
  max-width: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;

  #logistic {
    margin-top: 30px;
    width: 400px;
    height: auto;

    @media (max-width: 400px) {
      width: 300px;
    }

    @media (max-width: 330px) {
      width: 250px;
    }
  }
`;

export const SearchBox = styled.input`
  height: 70px;
  width: 60vw;
  border: none;
  text-align: left;
  padding-left: 50px;
  border-radius: 6px;
  font-size: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
  color: #044D75;
  transition: border 0.3s;

  @media (max-width: 531px) {
    font-size: 14px;
    height: 50px;
    width: 80vw;
  }

  ::-webkit-input-placeholder { 
    color:#044D75;
    font-size: 20px;
    text-align: left;

    @media (max-width: 531px) {
      font-size: 14px;
    }
  }

  ::-moz-placeholder { /* Mozilla Firefox 19+ */
    color:#044D75;
    font-size: 20px;
    text-align: left;

    @media (max-width: 531px) {
      font-size: 14px;
    }
  }

  &:focus {
    outline: none;
  }
`;