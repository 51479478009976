import styled from 'styled-components';
import { Link } from 'gatsby';

import Smart from '../../assets/images/SmartH.svg';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
`;

export const SmartLogo = styled(Smart)`
  height: 30px;
  width: 30px;
  margin: 20px 0;
`;

export const FooterMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
  text-align: center;
  margin-bottom: 20px;

  @media (max-width: 850px) {
    justify-content: center;
  }

  @media (max-width: 550px) {
    justify-content: center;
  }

  @media (max-width: 440px) {
    justify-content: center;
  }

  > div {
    width: 1rem;
    margin-top: 7px;
    height: 1px;
    background: #234577;
    transform: rotate(90deg);

    @media (max-width: 440px) {
      display: none;
    }
  }
`;

export const FooterLink = styled(Link)`
  text-decoration: none;
  font-size: 14px;
  font-family: 'Sora', sans-serif;
  color: #234577;
  transition: color 0.2s;

  &:hover {
    color: #F58634;
  }
`;

export const Copyright = styled.p`
  font-size: 14px;
  font-family: 'Sora', sans-serif;
  color: #234577;
  transition: color 0.2s;
`;