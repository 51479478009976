import React from "react";

import {
  HitLink,
  HitContent,
  HitTitle,
  HitDescription,
  HitTag
} from './styles';

const Hit = ({ hit }) => {
  return (
    <HitLink cover bg="#fff" to={`/${hit.class.slug}/${hit.slug}`}>
      <HitContent>
        <HitTag>{hit.tag ? hit.tag.toUpperCase() : hit.class.slug.toUpperCase()}</HitTag>
        <HitTitle>{hit.title}</HitTitle>
        <div></div>
        <HitDescription>{hit.description}</HitDescription>
      </HitContent>
    </HitLink>
  );
}

export default Hit;