import styled from 'styled-components';

export const Wrapper = styled.section`
  #top-arrow {
    height: 40px;
    width: 40px;
    position: fixed;
    fill: #044D75;
    bottom: 60px;
    transition: fill;
    border-radius: 100%;
    cursor: pointer;
    z-index: 99999999999999;

    @media (max-width: 1600px) {
      left: 20px;
    }

    @media (max-width: 411px) {
      height: 30px;
      width: 30px;
    }

    &:hover {
      fill: #489be8;
    }
  }
`;