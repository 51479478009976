import React from 'react';

import SearchLogo from '../../assets/images/smart-people.svg';

import Search from '../Search';

import {
  Wrapper,
} from './styles';

const KnowledgeCenter = () => {
  return (
    <Wrapper>

      <Search />

      <SearchLogo
        id="logistic"
        alt="3 amigos vestindo uma roupa com a logo da smartenvios sem texto acima de um computador com tela de uma caixa"
      />
    </Wrapper>
  );
}

export default KnowledgeCenter;