import styled from 'styled-components';
import AniLink from "gatsby-plugin-transition-link/AniLink";

export const HitLink = styled(AniLink)`
  text-decoration: none;
  list-style: none;
`;

export const HitContent = styled.div`
  display: flex;
    flex-direction: column;
  width: 77vw;
  grid-row-gap: 5px;
  padding: 10px;
  height: auto;
  background: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
  line-height: 20px;
  list-style: none;
  transition: ease-in 0.2s;

  @media (min-width: 1920px) {
    width: 30vw;
  }

  @media (min-width: 1400px) {
    width: 40vw;
  }

  @media (max-width: 531px) {
    display: flex;
    flex-direction: column;
    text-align: left;
    height: auto;
    width: 90vw;
  }
  
  &:hover {
    background: rgba(26,130,226,.1);
  }

`;

export const HitTitle = styled.h3`
  font-family: 'Sora', sans-serif;
  font-size: 14px;
  color: #044D75;
  font-weight: bold;
`;

export const HitDescription = styled.p`
  font-family: 'Sora', sans-serif;
  color: #294661;
  font-size: 12px;
`;

export const HitTag = styled.p`
  font-family: 'Sora', sans-serif;
  color: #F58634;
  font-size: 12px;
  padding-right: 10px;
`;