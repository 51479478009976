import React from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';

import TopArrow from '../../assets/images/topo.svg';

import { Wrapper } from './styles';

export default function GoTop() {
  return (
    <Wrapper>
      <TopArrow onClick={() => scrollTo('#topo')} id="top-arrow" />
    </Wrapper>
  );
};